import { Location } from '@angular/common';
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Flexible, FlexibleText } from '../../models/flexibles';
import { Link } from '../../models/link';
import { PageParente } from '../../models/page-parente';
import { ShareOption } from '../../models/share-option';
import { UrlService } from '../../shared/url.service';
import { copyUrlToClipboard } from '../../utils/utils.service';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { DialogSendEmailComponent } from '../dialog/dialog-send-email/dialog-send-email.component';
import { FlexibleOngletsComponent } from '../flexible/flexible-onglets/flexible-onglets.component';
import { FlexibleTextComponent } from '../flexible/flexible-text/flexible-text.component';
import { FlexiblesComponent } from '../flexible/flexibles/flexibles.component';
import { SelectImplantationComponent } from "../select-implantation/select-implantation.component";
import { ShareComponent } from '../share/share.component';
import { ZoomSurComponent } from '../zoom-sur/zoom-sur.component';

@Component({
  selector: 'app-page-parente',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    ShareComponent,
    FlexibleTextComponent,
    ZoomSurComponent,
    FlexibleOngletsComponent,
    forwardRef(() => FlexiblesComponent),
    MatExpansionModule,
    MatTabsModule,
    SelectImplantationComponent,
  ],
  templateUrl: './page-parente.component.html',
  styleUrl: './page-parente.component.scss'
})
export class PageParenteComponent implements OnInit, OnChanges {
  @Input() public content!: PageParente;
  headerFlexible: FlexibleText = { type: 'text', content: '', bColor: 'gris' };
  breadcrumbLinks: Link[] = [{ url: '/', label: 'Accueil' }];
  shareOptions: ShareOption[] = [];
  selectedFlexibles: Flexible[] = [];
  keys: { label: string, code: string }[] = [];
  selectedKey: number = 0;

  constructor(
    private location: Location,
    private urlService: UrlService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.shareOptions = [
      {
        label: 'Envoyer par mail',
        onclick: () => this.openDialogShareByEmail()
      },
      {
        label: 'Copier le lien',
        onclick: () => copyUrlToClipboard()
      }
    ];
  }

  ngOnInit() {
    // Ajout du parent dans le fil d'ariane
    this.content.parentPages?.forEach((parentPage) => {
      this.breadcrumbLinks.push({
        url: `/${parentPage.url}`,
        label: parentPage.title
      });
    });

    // create header
    this.headerFlexible.content = `<h1>${this.content?.title}</h1> <div class="chapo">${this.content?.chapo}</div>`;

    // select first key of the flexiblesTerritoriaux
    this.selectFirstKey();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Actions à executer lorsque content change
    if (changes['content']) {
      this.setHeaderContent();
      this.updateKeys();
      this.updateSelectedFlexibles();
    }
  }

  setHeaderContent() {
    this.headerFlexible.content = `<h1>${this.content?.title}</h1> <div class="chapo">${this.content?.chapo}</div>`
  }

  back(): void {
    this.location.back();
  }

  // Fonction pour obtenir le titre d'une route
  getTitleByUrl(url: string) {
    const matchingRoute = this.router.config.find(route => route.path === url.replace('/', ''));
    return matchingRoute?.title;
  }

  openDialogShareByEmail(): void {
    this.dialog.open(DialogSendEmailComponent, {
      width: '100%',
      maxWidth: '740px',
      data: { slug: this.content.path },
    });
  }

  selectFirstKey() {
    this.selectedKey = 0;
    this.updateSelectedFlexibles();
  }

  onSelectedKeyChange(selectedKey: number) {
    this.selectedKey = selectedKey;
    this.updateSelectedFlexibles();
  }

  updateKeys() {
    // Prevent duplicate entries
    const uniqueCodes = new Set<string>();
    this.keys = this.content.flexiblesTerritoriaux
      .filter(entry => {
        if (uniqueCodes.has(entry.code)) {
          return false;
        } else {
          uniqueCodes.add(entry.code);
          return true;
        }
      })
      .map(entry => ({
        label: entry.title ?? null,
        code: entry.code ?? null
      }));
  }

  updateSelectedFlexibles() {
    this.selectedFlexibles = this.content.flexiblesTerritoriaux[this.selectedKey]?.flexibles ?? [];
  }


}
